



































































































































import { Component, Watch, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { Route, NavigationGuardNext } from "vue-router";
import { cloneDeep } from "lodash";

import ConfigResponseEditor from "@/ab-tests/components/ConfigResponseEditor.vue";
import TargetedConfigurationModel from "@/ab-tests/models/TargetedConfigurationModel";
import ApplicationResponseModel, {
  EnvironmentModel,
  EnvironmentType,
} from "@/ab-tests/models/ApplicationResponseModel";
import ItemPreviewModel, {
  PREVIEW_ITEM_TYPE,
} from "@/ab-tests/models/ItemPreviewModel";
import { AppSection } from "@/shared/models";
import UnsavedChangesMixin from "@/shared/mixins/UnsavedChangesMixin";
import JsonUtil from "@/ab-tests/utils/JsonUtil";
import ConfigUtil from "@/ab-tests/utils/ConfigUtil";

@Component({
  components: {
    ConfigResponseEditor,
  },
})
export default class TargetedConfigurationEditConfigsView extends mixins(
  UnsavedChangesMixin
) {
  readonly ConfigUtil = ConfigUtil;
  readonly AppSection = AppSection;
  targetedConfig: TargetedConfigurationModel = new TargetedConfigurationModel();
  isWatchedTargeted = false;
  localTargetedResponse: ApplicationResponseModel =
    new ApplicationResponseModel();
  activeTab = "";
  fromRoute: Route | null = null;
  previewItemsAfterTransfer: Record<string, Array<ItemPreviewModel>> = {
    stage: [],
    production: [],
  };

  get instanceForWatchingUnsavedChanges() {
    // in order to receive the old and new value in the watch during deep viewing
    return cloneDeep(this.targetedConfig);
  }

  get isLoading(): boolean {
    return (
      this.$store.state.targetedConfig.loadingConfig ||
      this.$store.state.configsStore.isLoadingConfigsDiff
    );
  }

  get configId(): number {
    return Number.parseInt(this.$route.params.configId);
  }

  get isDeploymentInProgress(): boolean {
    return this.$store.state.targetedConfig.deploymentInProgress;
  }

  get initialTargetedConfig(): TargetedConfigurationModel {
    return this.$store.state.targetedConfig.targetedConfig;
  }

  @Watch("configId", { immediate: true })
  private watchConfigId(configId: string) {
    this.$store.dispatch("getTargetedConfig", configId);
  }

  @Watch("initialTargetedConfig", { immediate: true, deep: true })
  private watchTargetedConfig(targetedConfig: TargetedConfigurationModel) {
    this.targetedConfig = TargetedConfigurationModel.of(targetedConfig);
    this.localTargetedResponse = ApplicationResponseModel.of(
      targetedConfig.response
    );

    if (!this.isWatchedTargeted) {
      this.isWatchedTargeted = true;
      this.handleWatchingUnsavedChanges();
    }
  }

  @Watch("localTargetedResponse", { deep: true })
  private watchLocalTargetedResponse(
    localTargetedResponse: ApplicationResponseModel
  ) {
    this.targetedConfig.response = ApplicationResponseModel.of(
      localTargetedResponse
    );
  }

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
    next((vm: Vue & { fromRoute?: Route }) => {
      vm.fromRoute = from;
    });
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (
      this.hasUnsavedChanges &&
      this.currentRoutePath === from.path &&
      !this.isSavedForm
    ) {
      this.showUnsavedChangesDialog(to);
    } else {
      next();
    }
  }

  setConfig(val: string) {
    if (this.activeTab === "tab-stage") {
      this.localTargetedResponse.stage.response = val;
    }

    if (this.activeTab === "tab-production") {
      this.localTargetedResponse.production.response = val;
    }

    this.isSavedForm = false;
  }

  async deployToProd(isDeployAndSync: boolean) {
    if (isDeployAndSync) {
      this.localTargetedResponse.stage.response =
        this.localTargetedResponse.production.response;
    }

    this.targetedConfig.response = ApplicationResponseModel.of(
      this.localTargetedResponse
    );
    const targetedConfigForRequest = cloneDeep(this.targetedConfig);

    await this.$store.dispatch("deployTargetedConfig", {
      config: targetedConfigForRequest,
      environmentType: EnvironmentType.PROD,
    });

    if (isDeployAndSync) {
      await this.$store.dispatch("deployTargetedConfig", {
        config: targetedConfigForRequest,
        environmentType: EnvironmentType.STAGE,
      });
    }

    this.resetPreviewItemsData();
    this.isSavedForm = true;
  }

  async deployToStage(isDeployAndSync: boolean) {
    if (isDeployAndSync) {
      this.localTargetedResponse.production.response =
        this.localTargetedResponse.stage.response;
    }

    this.targetedConfig.response = ApplicationResponseModel.of(
      this.localTargetedResponse
    );
    const targetedConfigForRequest = cloneDeep(this.targetedConfig);

    await this.$store.dispatch("deployTargetedConfig", {
      config: targetedConfigForRequest,
      environmentType: EnvironmentType.STAGE,
    });

    if (isDeployAndSync) {
      await this.$store.dispatch("deployTargetedConfig", {
        config: targetedConfigForRequest,
        environmentType: EnvironmentType.PROD,
      });
    }

    this.resetPreviewItemsData();
    this.isSavedForm = true;
  }

  transferToProd() {
    this.localTargetedResponse.production.response =
      this.localTargetedResponse.stage.response;
    this.targetedConfig.response = ApplicationResponseModel.of(
      this.localTargetedResponse
    );
    this.fillPreviewItemsAfterTransfer("production", "stage");
    this.activeTab = "tab-production";
    this.isSavedForm = false;
  }

  transferToStage() {
    this.localTargetedResponse.stage.response =
      this.localTargetedResponse.production.response;
    this.targetedConfig.response = ApplicationResponseModel.of(
      this.localTargetedResponse
    );
    this.fillPreviewItemsAfterTransfer("stage", "production");
    this.activeTab = "tab-stage";
    this.isSavedForm = false;
  }

  fillPreviewItemsAfterTransfer(to: string, from: string) {
    this.previewItemsAfterTransfer[to] = [
      ...JsonUtil.formattingFromJson(
        this.initialTargetedConfig.response[to as "stage" | "production"]
          .response
      )
        .filter(({ key }: { key: string }) =>
          JsonUtil.formattingFromJson(
            this.localTargetedResponse[from as "stage" | "production"].response
          ).every((item: any) => item.key !== key)
        )
        .map(({ key, value }: { key: string; value: string }) => ({
          key,
          value,
          type: PREVIEW_ITEM_TYPE.DELETED,
        })),
      ...JsonUtil.formattingFromJson(
        this.localTargetedResponse[from as "stage" | "production"].response
      )
        .filter(({ key, value }: { key: string; value: string }) =>
          JsonUtil.formattingFromJson(
            this.initialTargetedConfig.response[to as "stage" | "production"]
              .response
          ).some((item: any) => item.key === key && item.value !== value)
        )
        .map(({ key, value }: { key: string; value: string }) => ({
          key,
          value,
          type: PREVIEW_ITEM_TYPE.EDITED,
        })),
      ...JsonUtil.formattingFromJson(
        this.localTargetedResponse[from as "stage" | "production"].response
      )
        .filter(({ key }: { key: string }) =>
          JsonUtil.formattingFromJson(
            this.initialTargetedConfig.response[to as "stage" | "production"]
              .response
          ).every((item: any) => item.key !== key)
        )
        .map(({ key, value }: { key: string; value: string }) => ({
          key,
          value,
          type: PREVIEW_ITEM_TYPE.ADDED,
        })),
    ];
  }

  resetPreviewItemsData() {
    this.previewItemsAfterTransfer = {
      stage: [],
      production: [],
    };
  }

  handleImportToStage(value: string) {
    this.localTargetedResponse.stage.response = value;
  }

  handleCopyResponse({
    payload,
    field,
  }: {
    payload: ApplicationResponseModel;
    field: "stage" | "production";
  }) {
    if (this.activeTab === "tab-stage") {
      this.localTargetedResponse.stage.response = (
        payload[field] as EnvironmentModel
      ).response;
    } else if (this.activeTab === "tab-production") {
      this.localTargetedResponse.production.response = (
        payload[field] as EnvironmentModel
      ).response;
    }
  }
}
