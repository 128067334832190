var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","align":"stretch","atid":"targeted_edit_view"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":"","loading":_vm.isLoading}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$lang( "targetedConfig.editConfigsTitle", _vm.initialTargetedConfig.name ))+" "),_c('v-spacer'),_c('v-btn',{staticClass:"ml-3",attrs:{"link":"","to":{
              name: _vm.AppSection.TARGETED_CONFIGS,
            }}},[_vm._v(" "+_vm._s(_vm.$lang("btn.toTheList"))+" ")]),(_vm.fromRoute && _vm.fromRoute.name === 'targeted_view')?_c('v-btn',{staticClass:"ml-3",attrs:{"to":{ name: _vm.fromRoute.name }}},[_vm._v(" "+_vm._s(_vm.$lang("btn.back"))+" ")]):_vm._e()],1),_c('v-card-text',{staticClass:"pa-0"},[_c('v-tabs',{attrs:{"background-color":"transparent","grow":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab',{attrs:{"href":"#tab-stage"}},[(
                  _vm.ConfigUtil.hasDiffBetweenConfigs(
                    _vm.initialTargetedConfig.response.stage.response,
                    _vm.targetedConfig.response.stage.response
                  )
                )?_c('div',{staticClass:"warning rounded-circle",staticStyle:{"width":"10px","height":"10px"}}):_vm._e(),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$lang("commonConfig.stage"))+" ")])]),_c('v-tab',{attrs:{"href":"#tab-production"}},[(
                  _vm.ConfigUtil.hasDiffBetweenConfigs(
                    _vm.initialTargetedConfig.response.production.response,
                    _vm.targetedConfig.response.production.response
                  )
                )?_c('div',{staticClass:"warning rounded-circle",staticStyle:{"width":"10px","height":"10px"}}):_vm._e(),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$lang("commonConfig.production"))+" ")])]),_c('v-tab-item',{attrs:{"value":"tab-stage"}},[_c('config-response-editor',{attrs:{"value":_vm.localTargetedResponse.stage.response,"is-deployer-visible":true,"active-tab":_vm.activeTab,"default-config-response":_vm.targetedConfig.response,"initial-config":_vm.initialTargetedConfig.response,"editable":_vm.targetedConfig.editable,"config-parent-id":_vm.targetedConfig.parentId,"has-non-deployed-changes":_vm.ConfigUtil.hasDiffBetweenConfigs(
                    _vm.initialTargetedConfig.response.stage.response,
                    _vm.targetedConfig.response.stage.response
                  ),"is-deployment-in-progress":_vm.isDeploymentInProgress,"preview-items-after-transfer":_vm.previewItemsAfterTransfer.stage,"has-deploy-access":_vm.targetedConfig.hasDeployAccess},on:{"save":_vm.setConfig,"deploy":_vm.deployToStage,"import":_vm.handleImportToStage,"transfer":_vm.transferToProd,"copyResponse":_vm.handleCopyResponse}})],1),_c('v-tab-item',{attrs:{"value":"tab-production"}},[_c('config-response-editor',{attrs:{"value":_vm.localTargetedResponse.production.response,"active-tab":_vm.activeTab,"is-deployer-visible":true,"default-config-response":_vm.targetedConfig.response,"initial-config":_vm.initialTargetedConfig.response,"editable":_vm.targetedConfig.editable,"config-parent-id":_vm.targetedConfig.parentId,"has-non-deployed-changes":_vm.ConfigUtil.hasDiffBetweenConfigs(
                    _vm.initialTargetedConfig.response.production.response,
                    _vm.targetedConfig.response.production.response
                  ),"is-deployment-in-progress":_vm.isDeploymentInProgress,"preview-items-after-transfer":_vm.previewItemsAfterTransfer.production,"has-deploy-access":_vm.targetedConfig.hasDeployAccess},on:{"save":_vm.setConfig,"deploy":_vm.deployToProd,"transfer":_vm.transferToStage,"copyResponse":_vm.handleCopyResponse}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }